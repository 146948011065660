// import React from 'react'; 
// import {
// 	atom,
// 	selector,
// 	useRecoilState,
// 	useRecoilValue,
// } from 'recoil';

export default function Foot() {
	return " "
}
